"use client";
import PriceCard from "./PriceCard";
import { PlanCardType } from "../types/types";
import PlanBase from "./PlanBase";
import { getOuterContainerStyle } from "./utils";
import { AllPricingItems } from "@/lib/server/pricing/types";

const PlatinumPlanCard = ({
  baseWrapperForPlanBannerStyle,
  activeSubscriptionPlan,
  billing,
  // pricingStore,
  pricingItems,
}: PlanCardType) => {
  const planType =
    billing === "monthly" ? "platinumPlan" : "platinumPlanYearly";
  return (
    <PlanBase
      baseWrapperForPlanBannerStyle={baseWrapperForPlanBannerStyle}
      activeSubscriptionPlan={activeSubscriptionPlan}
      planType={planType}
      billing={billing}
      // pricingStore={pricingStore}
      pricingItems={pricingItems}
    />
  );
};

const PlatinumPlanViewOnly = ({
  pricingItems,
}: {
  pricingItems: AllPricingItems;
}) => {
  const platinumPricingItem = pricingItems["platinumPlan"];
  const outerContainerStyle = getOuterContainerStyle({
    object: { isViewOnly: true },
    planType: "platinumPlan",
  });
  if (!platinumPricingItem) return null;
  return (
    <PriceCard
      isAdmin={false} //no need to show edit overlay here
      outerContainerStyle={outerContainerStyle}
      wrapperForPlanBannerStyle={"hidden"}
      wrapperForPlanBannerText=""
      planTitle={platinumPricingItem.plan}
      tagline={platinumPricingItem.tagline}
      priceDisplay={<></>}
      cardMiddleZone={
        <>{platinumPricingItem.quota.toLocaleString()} submissions/month</>
      }
      features={platinumPricingItem.features}
      footer={<></>}
      cancellationNotice={<></>}
      isComingSoon={platinumPricingItem.comingSoon}
    />
  );
};

export { PlatinumPlanCard, PlatinumPlanViewOnly };
