"use client";
import PriceCard from "./PriceCard";
import { PlanCardType } from "../types/types";
import { getOuterContainerStyle } from "./utils";
import PlanBase from "./PlanBase";
import { AllPricingItems } from "@/lib/server/pricing/types";

const DiamondPlanCard = ({
  baseWrapperForPlanBannerStyle,
  activeSubscriptionPlan,
  billing,
  pricingItems,
}: PlanCardType) => {
  const planType = billing === "monthly" ? "diamondPlan" : "diamondPlanYearly";
  return (
    <PlanBase
      baseWrapperForPlanBannerStyle={baseWrapperForPlanBannerStyle}
      activeSubscriptionPlan={activeSubscriptionPlan}
      planType={planType}
      billing={billing}
      pricingItems={pricingItems}
    />
  );
};

const DiamondPlanViewOnly = ({
  pricingItems,
}: {
  pricingItems: AllPricingItems;
}) => {
  const diamondPricingItem = pricingItems["diamondPlan"];

  const outerContainerStyle = getOuterContainerStyle({
    object: { isViewOnly: true },
    planType: "diamondPlan",
  });

  if (!diamondPricingItem) return null;
  return (
    <PriceCard
      isAdmin={false} //no need to show edit overlay here
      outerContainerStyle={outerContainerStyle}
      wrapperForPlanBannerStyle={"hidden"}
      wrapperForPlanBannerText=""
      planTitle={diamondPricingItem.plan}
      tagline={diamondPricingItem.tagline}
      priceDisplay={<></>}
      cardMiddleZone={
        <>{diamondPricingItem.quota.toLocaleString()} submissions/month</>
      }
      features={diamondPricingItem.features}
      footer={<></>}
      reverseTextColor={true}
      cancellationNotice={<></>}
      isComingSoon={diamondPricingItem.comingSoon}
    />
  );
};

export { DiamondPlanCard, DiamondPlanViewOnly };
